import { CDN_IMG } from './endpoints'
import { KEYWORD_EYE, KEYWORD_SERUM, KEYWORD_SKIN } from './funnels'

export const SYSTEM_PRODUCT = 'proven-system'
export const DAY_MOISTURIZER_PRODUCT = 'proven-spf'
export const NIGHT_CREAM_PRODUCT = 'proven-night-cream'
export const CLEANSER_PRODUCT = 'proven-cleanser'

export const EYE_CREAM_PRODUCT = 'eye-cream-duo'
export const NIGHT_EYE_CREAM_PRODUCT = 'night-eye-cream'
export const DAY_EYE_CREAM_PRODUCT = 'day-eye-cream'
export const SERUM = 'serum'

export const SERUM_PRODUCT = 'serum'
export const HYDRATION_BOOST_DAY_MOISTURIZER = 'Hydration-Boost-Day-Moisturizer'
export const SPOT_TREATMENT_SERUM = 'spot-treatment-serum'
export const MICELLAR_WATER = 'micellar-water'
export const PILLOW_CASE = 'pillow-case'

export const PRODUCT_ID_BY_INTENT = {
  [KEYWORD_EYE]: EYE_CREAM_PRODUCT,
  [KEYWORD_SKIN]: SYSTEM_PRODUCT,
  [KEYWORD_SERUM]: SERUM_PRODUCT
}

export const COMBO_PRODUCTS = [SYSTEM_PRODUCT, EYE_CREAM_PRODUCT, SERUM_PRODUCT]

export const EYE_CREAM_PRODUCT_IDS = [DAY_EYE_CREAM_PRODUCT, NIGHT_EYE_CREAM_PRODUCT]

export const ONE_TIME_PRODUCT_IDS = [
  'mini-cryo-Roller',
  'mini-cryo-roller',
  'healing-dots-acne-patch-2k',
  'acne-spot-treatment',
  'cryo-roller',
  'healing-dots-acne-patch-4pk',
  'pore-purifying-pad',
  'makeup-removing-rounds'
]

export const SHOP_PRODUCT_CATEGORY_MAPPINGS = {
  'proven-cleanser': 'Cleanser',
  'proven-system': 'Proven System',
  'proven-spf': 'Moisturizer',
  'proven-night-cream': 'Night Cream',
  'day-eye-cream': 'Day Eye Cream',
  'night-eye-cream': 'Night Eye Cream',
  'eye-cream-duo': 'Eye Cream Duo',
  serum: 'Serum'
}

export const ALL_SHOP_PRODUCTS = [
  SYSTEM_PRODUCT,
  CLEANSER_PRODUCT,
  DAY_MOISTURIZER_PRODUCT,
  NIGHT_CREAM_PRODUCT,
  EYE_CREAM_PRODUCT,
  NIGHT_EYE_CREAM_PRODUCT,
  DAY_EYE_CREAM_PRODUCT,
  SERUM
]

export const productImages = [
  {
    id: 'cleanser',
    product: `${CDN_IMG}home/cleanser-individual-bottle.png`,
    texture: `${CDN_IMG}results/cleanser-texture.jpg`,
    backgroundColor: '#EFF9F4'
  },
  {
    id: 'spf',
    product: `${CDN_IMG}home/spf-individual-bottle.png`,
    texture: `${CDN_IMG}results/spf-texture.jpg`,
    backgroundColor: '#F9F6EE'
  },
  {
    id: 'night-cream',
    product: `${CDN_IMG}home/night-cream-individual-bottle.png`,
    texture: `${CDN_IMG}results/night-cream-texture.jpg`,
    backgroundColor: '#D4C9CD'
  }
]

export const SYSTEM_PRODUCT_DESKTOP = `${CDN_IMG}results/1120mega-3products-mobile.png`

export const SYSTEM_PRODUCT_MOBILE = `${CDN_IMG}results/1120mega-3products-desktop.png`

export const EYE_CREAM_DESKTOP = `${CDN_IMG}rud/upsell-eye-cream-desktop.png`

export const EYE_CREAM_MOBILE = `${CDN_IMG}rud/upsell-eye-cream-mobile.png`

export const SHOP_BACKGROUND =
  'https://dl7bo1dy930sf.cloudfront.net/img/rud/shop/Shop-thumb-bg-texture.png'

export const SUBSCRIPTION_OPTION_FOR_NEXT_ACTION = 'subscription'
export const ONE_TIME_OPTION_FOR_NEXT_ACTION = 'one-time'
export const PUBLIC_SYSTEM_PRODUCT_URL = '/personalized-subscription'
